<template>
    <header class="page-title">
        <h1><i class="bi bi-gear"></i> ユーザ管理</h1>
    </header>

    <section class="section">
        <div class="row mb-3">
            <div class="col-4">
                <form-input v-model="condition.keyword"></form-input>
            </div>
            <div class="col-4">
                <form-select v-model="condition.is_member" :options="UserIsMember.options()" empty_option="全て"></form-select>
            </div>
            <div class="col-4">
                <button class="btn btn-primary" @click="search">表示</button>
            </div>
            <div class="col-12 text-end">
                <router-link class="btn btn-outline-primary" :to="{name: 'SystemUserAdd'}"><i class="bi bi-plus"></i> 新規登録</router-link>
            </div>
        </div>
        <table class="table mb-3">
            <thead class="table-dark text-center">
                <tr>
                    <th class="col-4">氏名</th>
                    <th class="col-4">部門</th>
                    <th class="col-6">在席期間</th>
                    <th class="col-6">権限</th>
                    <th class="col-4"></th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(3)" :key="i">
                    <td><text-loader :width="8"></text-loader></td>
                    <td><text-loader :width="8"></text-loader></td>
                    <td><text-loader :width="12"></text-loader></td>
                    <td><text-loader :width="12"></text-loader></td>
                    <td class="text-nowrap">
                        <button class="btn btn-primary" disabled>編集</button>
                        <button class="btn btn-outline-primary ms-3" disabled>標準労務単価</button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="users.length === 0">
                <tr>
                    <td colspan="5">該当するユーザがいません</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="user of users" :key="user.user_id">
                    <td>{{ user.user_name }}</td>
                    <td>{{ user.department?.department_name }}</td>
                    <td>{{ user.member_period }}</td>
                    <td>
                        <i v-if="user.permissions.includes(Permission.VIEW_OTHERS_REPORT)" class="me-2 bi bi-clipboard-fill text-secondary" title="他人の日報を閲覧"></i>
                        <i v-else class="me-2 bi bi-clipboard-fill text-light" title="他人の日報を閲覧"></i>
                        <i v-if="user.permissions.includes(Permission.EDIT_OTHERS_REPORT)" class="me-2 bi bi-clipboard-plus-fill text-secondary" title="他人の日報を編集"></i>
                        <i v-else class="me-2 bi bi-clipboard-plus-fill text-light" title="他人の日報を編集"></i>
                        <i v-if="user.permissions.includes(Permission.VIEW_PROFIT)" class="me-2 bi bi-calendar2-fill text-secondary" title="収支を閲覧"></i>
                        <i v-else class="me-2 bi bi-calendar2-fill text-light" title="収支を閲覧"></i>
                        <i v-if="user.permissions.includes(Permission.EDIT_PROFIT)" class="me-2 bi bi-calendar2-plus-fill text-secondary" title="収支を編集"></i>
                        <i v-else class="me-2 bi bi-calendar2-plus-fill text-light" title="収支を編集"></i>
                        <i v-if="user.permissions.includes(Permission.EXPORT_CSV)" class="me-2 bi bi-download text-secondary" title="CSVエクスポート"></i>
                        <i v-else class="me-2 bi bi-download text-light" title="CSVエクスポート"></i>
                        <i v-if="user.permissions.includes(Permission.IMPORT_CSV)" class="me-2 bi bi-upload text-secondary" title="CSVインポート"></i>
                        <i v-else class="me-2 bi bi-upload text-light" title="CSVインポート"></i>
                        <i v-if="user.permissions.includes(Permission.MANAGE_MASTER)" class="me-2 bi bi-wrench-adjustable-circle text-secondary" title="マスタ管理"></i>
                        <i v-else class="me-2 bi bi-wrench-adjustable-circle text-light" title="マスタ管理"></i>
                        <i v-if="user.permissions.includes(Permission.MANAGE_USER)" class="me-2 bi bi-person-square text-secondary" title="ユーザ管理"></i>
                        <i v-else class="me-2 bi bi-person-square text-light" title="ユーザ管理"></i>
                        <i v-if="user.permissions.includes(Permission.EDIT_COMPANY)" class="me-2 bi bi-briefcase-fill text-secondary" title="会社情報設定"></i>
                        <i v-else class="me-2 bi bi-briefcase-fill text-light" title="会社情報設定"></i>
                        <i v-if="user.permissions.includes(Permission.PAY)" class="me-2 bi bi-credit-card text-secondary" title="支払"></i>
                        <i v-else class="me-2 bi bi-credit-card text-light" title="支払"></i>
                    </td>
                    <td class="text-nowrap">
                        <router-link class="btn btn-primary" :to="{name: 'SystemUserEdit', params: {user: user.user_id}}">編集</router-link>
                        <router-link class="btn btn-outline-primary ms-3" :to="{name: 'SystemUserLaborCost', params: {user: user.user_id}}">標準労務単価</router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <paginator :meta="paginator" @move="search" v-if="paginator"></paginator>
    </section>
</template>

<script>
import Paginator from '@/components/tools/Paginator';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import TextLoader from '@/components/tools/TextLoader';
import User from "@/models/entities/user";
import UserIsMember from "@/models/enums/user-is-member";
import Permission from '@/models/enums/permission';

export default {
    name: 'SystemUser',
    components: {
        Paginator,
        FormInput,
        FormSelect,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            users: [],
            loading: false,
            condition: {
                keyword: null,
                is_member: 1,
                page: 1,
            },
            UserIsMember,
            Permission,
            paginator: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        search(page = 1) {
            this.users.splice(0);
            this.paginator = null;
            this.loading = true;
            this.condition.page = page;
            this.$http.get('/system/user', {params: this.condition})
            .then((response) => {
                this.users = response.data.data.map((row) => {return new User(row)});
                this.paginator = response.data.meta;
            })
            .finally(() => {
                this.loading = false;
            });
        },
    },
}
</script>

<style scoped>
</style>
