import Enum from './enum'

/**
 * ユーザ在席状態 (検索用)
 */
class UserIsMember extends Enum {
    static YES = 1; //在籍中
    static NO = 0; //在席期間外

    static values() {
        return {
            [this.YES]: '在籍中',
            [this.NO]: '在席期間外',
        }
    }
}

export default UserIsMember;
